import Vue from 'vue'
import Vuex from 'vuex'
import request from '../libs/request.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    linkList:[]
  },
  getters: {
  },
  mutations: {
    setLink(state,linkList){
      state.linkList=linkList
    }
  },
  actions: {
    async getLink({commit}){
      let res = await request.post('link/getAll')
      if (res.data.code===0) {
        commit('setLink',res.data.data)
      }else{
        console.error('数据请求失败')
      }
    }
  },
  modules: {
  }
})
